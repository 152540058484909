import axios from "axios";
import { Toast } from 'vant';
import { v4 as uuidv4 } from 'uuid';

const proUrl = 'https://activity.aiautopilot.net'

// 创建一个新的Axios实例
const api = axios.create({
  // 设置基本URL
  baseURL: process.env.NODE_ENV === 'development' ? proUrl : proUrl,
  timeout: 20000, // 设置请求超时时间
  proxy: {
    protocol: 'https',
  }
});

// 添加请求拦截器
api.interceptors.request.use(
  config => {
    // 在发送请求之前做些什么，例如添加请求头部信息
    config.headers['isPc'] = true

    let deviceId = localStorage.getItem('deviceId')
    if (!deviceId) {
      deviceId = uuidv4()
      localStorage.setItem('deviceId', deviceId)
    }
    config.headers['uuid'] = deviceId

    return config;
  },
  error => {
    // 处理请求错误
    console.error(error);
    return Promise.reject(error);
  }
);

// 添加响应拦截器
api.interceptors.response.use(
  response => {
    // console.log(response.headers);
    // 对响应数据进行处理，例如解析响应内容
    if (response.headers['content-type'] == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
    ) {
      return response
    }
    // console.log(response);
    if (response.data.code !== 200 && response.data.status !== 200) {
      Toast(response.data.content);
      return response.data;
    } else {
      return response.data;
    }

  },
  error => {
    // 处理响应错误
    // console.error(error);
    if (error.response && error.response.status === 404) {
      // console.error('请求的资源不存在');
      Toast(error.message);
      // 可以在这里进行错误处理或者重定向
      // 例如返回一个自定义的响应对象
      return Promise.reject(new Error('资源不存在'));
    }
    return Promise.reject(error);
  }
);

export default api;