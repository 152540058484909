<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
body {
  padding: 0;
  box-sizing: border-box;
  margin: 0;
}
</style>


<!-- <template>
  <VideoEditor />
</template>
<script>
import VideoEditor from './components/VideoEditor.vue';
export default {
  name: 'App',
  components: {
    VideoEditor,
  },
};
</script> -->
